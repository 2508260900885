import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
// Connects to data-controller="product-form"
export default class extends Controller {

  static targets = [
                    'nonReserveRadio',
                    'reserveRadio',
                    'shippingDate',
                    'reserveStockText',
                    'nonReserveStockText'
                  ]

  // mock: assets/js/sell.js
  connect() {
    this.textareaValidation('.limit50', 50);
    this.textareaValidation('.limit100', 100);
    this.textareaValidation('.limit1000', 1000);
    this.cancelPolicyChecked();
    this.cancelPolicyEvent();
    this.anyButtonEvent();
    this.deliveryChargesEvent();
    this.setSpecialNotesTemplete()
    this.loadSellType();
  }

  // テキストエリアの文字制限
  textareaValidation(selector, limitNum) {
    // バリデーションエラー後の再レンダリング時にエラーを表示
    document.querySelectorAll(selector).forEach(element => this.textareaError(element, limitNum));
    // イベント発火
    $(selector).on("keydown keyup keypress paste change", (e) => this.textareaEvent(e, limitNum));
  }

  // 出品画面ーキャンセルポリシー
  cancelPolicyEvent() {
    $('input[name="farmers_product[is_base_policy]"]').on('change', function () {
      // 全てのクラスを外す
      $('.js_cancelPolicy_list li').removeClass('is_current');
      // checked状態かどうか判断
      if($(this).is(':checked')){
        // TRUE:枠線用クラス追加
        $(this).parents('li').addClass('is_current');
      };
      // キャンセルポリシー入力欄の表示非表示
      if ($('.js_originPolicy').prop('checked')) {
        $('.js_cancelPolicy_textArea').slideDown();
      } else {
        $('.js_cancelPolicy_textArea').slideUp();
      }
    });
  }

  // キャンセルポリシー入力欄の表示非表示
  cancelPolicyChecked() {
    if ($('.js_originPolicy').prop('checked')) {
      $('.js_cancelPolicy_textArea').slideDown();
    } else {
      $('.js_cancelPolicy_textArea').slideUp();
    }
  }

  textareaEvent(elem, limitNUm) {
    const element = elem.currentTarget;
    this.textareaError(element, limitNUm);
  }

  // テキストエリアのバリデーション
  textareaError(element, limitNUm) {
    element.parentElement.previousElementSibling.querySelector(".form_num").textContent = limitNUm - element.value.length;
    // 10文字以内は色変更・太字
    if (Number(element.parentElement.previousElementSibling.querySelector(".form_num").textContent) < 10) {
      element.parentElement.previousElementSibling.querySelector(".form_num").style.color = "#e35768";
      element.parentElement.previousElementSibling.querySelector(".form_num").style.fontWeight = "bold";
    } else {
      element.parentElement.previousElementSibling.querySelector(".form_num").style.color = "#444444";
      element.parentElement.previousElementSibling.querySelector(".form_num").style.fontWeight = "normal";
    }

    // 制限文字数に達したらエラーメッセージ
    if (element.value.length > limitNUm) {
      element.classList.add("is_error");
    } else {
      element.classList.remove("is_error");
    }
  }

  // 任意項目の開閉
  anyButtonEvent() {
    const anyBtn = $("#any_btn");
    const anyArea = $(".sell_any");

    anyArea.hide();

    // 任意項目に入力エラーがある場合は最初から表示しておく
    if (anyArea.hasClass("openAnyArea")) {
      anyArea.fadeToggle();
      anyBtn.toggleClass("is_open");
      anyBtn.html(`任意項目を閉じる<i class="fa-solid fa-chevron-up"></i>`);
    }

    // ボタンをクリックしたら切替え
    anyBtn.on("click", function () {
      anyArea.fadeToggle();
      anyBtn.toggleClass("is_open");

      if (anyBtn.hasClass("is_open")) {
        anyBtn.html(`任意項目を閉じる<i class="fa-solid fa-chevron-up"></i>`);
      } else {
        anyBtn.html(`任意項目を開く<i class="fa-solid fa-chevron-down"></i>`);
      }
    });
  }

  // 地域別送料設定フォームの初期表示
  deliveryChargesEvent() {
    const optionArea = $(".js_optionArea");
    const hasSetting = document.querySelectorAll('.has_setting');

    // 初期表示で閉じる
    optionArea.hide();

    // 設定が１つでも入力されていれば開いておく
    if (hasSetting.length > 0) {
      this.toggleDeliveryCharges();
    }
  }

  // 地域別送料設定エリアの開閉
  toggleDeliveryCharges() {
    const optionBtn = $(".js_optionBtn");
    optionBtn.parent().next().fadeToggle();
    optionBtn.toggleClass("is_open");

    if (optionBtn.hasClass("is_open")) {
      optionBtn.find('span').text(`閉じる`);
    } else {
      optionBtn.find('span').text(`開く`);
    }
  }

  applyBasicSetting() {
    // data属性を利用してデータを取得する
    const basicSettingDataElement = document.getElementById('basic-setting-data');
    const prefectureCode = basicSettingDataElement.getAttribute('data-prefecture-code');
    const deliveryMethod = basicSettingDataElement.getAttribute('data-delivery-method');
    const deliveryArea = basicSettingDataElement.getAttribute('data-delivery-area');
    const deliveryDate = basicSettingDataElement.getAttribute('data-delivery-date');
    const caution = basicSettingDataElement.getAttribute('data-caution');
    const cancelPolicy = basicSettingDataElement.getAttribute('data-cancel-policy');

    // 指定された部分にデータをセットする
    const prefectureSelect = document.querySelector('.js_prefecture_code');
    const deliveryMethodInput = document.querySelector('.js_delivery_method');
    const deliveryAreaInput = document.querySelector('.js_delivery_area');
    const deliveryDateInput = document.querySelector('.js_delivery_date');
    const cautionInput = document.querySelector('.js_caution');
    const cancelPolicyTextArea = document.querySelector('.js_cancel_policy');

    if (prefectureSelect && prefectureCode) {
      prefectureSelect.value = prefectureCode;
    }

    if (deliveryMethodInput && deliveryMethod) {
      deliveryMethodInput.value = deliveryMethod;
    }

    if (deliveryAreaInput && deliveryArea) {
      deliveryAreaInput.value = deliveryArea;
    }

    if (deliveryDateInput && deliveryDate) {
      deliveryDateInput.value = deliveryDate;
    }

    if (cautionInput && caution) {
      cautionInput.value = caution;
      // 残り文字数を更新する
      this.textareaValidation('.limit1000', 1000);
    }

    if (cancelPolicyTextArea && cancelPolicy) {
      // 独自のキャンセルポリシーの方にチェックを入れる
      const basePolicyRadioButton = document.getElementById('basePolicy');
      const originPolicyRadioButton = document.getElementById('originPolicy');
      const basePolicyLi = basePolicyRadioButton.closest('li');
      const originPolicyLi = originPolicyRadioButton.closest('li');
      basePolicyRadioButton.checked = false;
      basePolicyLi.classList.remove('is_current');
      originPolicyRadioButton.checked = true;
      originPolicyLi.classList.add('is_current');

      cancelPolicyTextArea.value = cancelPolicy;

      this.cancelPolicyChecked();
      this.cancelPolicyEvent();
    }

    // flashメッセージを表示する
    this.showFlashMessage("基本情報の内容を反映しました");
  }

  showFlashMessage(message) {
    // 新しいp要素を作成
    const flashMessageElement = document.createElement('p');
    flashMessageElement.className = 'statusbar success_farmer is_active';
    flashMessageElement.textContent = message;

    // フラッシュメッセージを挿入する場所を選択して、その子要素として追加
    const container = document.querySelector('main.farmer.alertPage');
    if (container) {
        container.insertBefore(flashMessageElement, container.firstChild);
    }

    // 7秒後にメッセージを非表示にする
    setTimeout(() => {
      flashMessageElement.style.display = 'none';
    }, 7000);
  }

  // 特記事項テンプレートの挿入
  setSpecialNotesTemplete() {
    const templateButton = document.querySelector(".js_btn_template");

    // ボタンがクリックされたときのイベントリスナーを追加
    templateButton.addEventListener("click", () => {
      // textareaの要素を取得
      const textarea = document.querySelector(".js_templeteText");

      // textareaの内容を指定されたテンプレートの文字列に置き換える
      textarea.value = '■配送日時の希望があれば入力をお願いします。\n\n■宅配ボックスや置き配など、受取方法の希望があれば入力をお願いします。\n\n■商品に関連するアレルギーがあれば入力をお願いします。';

      // 残り文字数の更新をする
      this.textareaError(textarea, 1000);
    });
  }

  // 販売方法の変更
  changeSellType() {
    if (this.nonReserveRadioTarget.checked) {
      this.reserveModal = Modal.getOrCreateInstance(document.getElementById('sellTypeChangeNormalModal'))
      this.reserveModal.show()
    }else{
      this.nonReserveModal = Modal.getOrCreateInstance(document.getElementById('sellTypeChangeReserveModal'))
      this.nonReserveModal.show()
    }
  }

  // ページロード時
  loadSellType() {
    if (this.nonReserveRadioTarget.checked) {
      this.changeSellTypeNonReserve()
    }else{
      this.changeSellTypeReserve()
    }
  }

  // モーダルにて通常販売を選択した場合
  changeSellTypeNonReserve() {
    this.shippingDateTarget.style.display ='none';
    this.nonReserveRadioTarget.checked = true;
    this.nonReserveRadioTarget.parentElement.parentElement.classList.add('is_current');
    this.reserveRadioTarget.checked = false;
    this.reserveRadioTarget.parentElement.parentElement.classList.remove('is_current');
    this.nonReserveStockTextTarget.style.display = 'block';
    this.reserveStockTextTarget.style.display = 'none';
  }

  // モーダルにて予約販売を選択した場合
  changeSellTypeReserve() {
    this.shippingDateTarget.style.display ='block';
    this.nonReserveRadioTarget.checked = false;
    this.nonReserveRadioTarget.parentElement.parentElement.classList.remove('is_current');
    this.reserveRadioTarget.checked = true;
    this.reserveRadioTarget.parentElement.parentElement.classList.add('is_current');
    this.nonReserveStockTextTarget.style.display = 'none';
    this.reserveStockTextTarget.style.display = 'block';
  }
}

