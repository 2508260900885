import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['licenseIds','permissionSelect','permissionOthers','licenseImage','popupContainer']

  connect() {
    this.changePermission()
    // 選択済み資格・許可証IDの取得（出品フォーム用）
    this.setLicenseIds()
  }

  /**
   * 資格・許可証選択時
   *
   */
  changePermission() {
    if (this.hasPermissionOthersTarget) {
      this.permissionOthersTarget.style.display ="none";
    }
    // 「その他」が選択されている場合に詳細用のテキストボックスを表示
    if (this.hasPermissionSelectTarget && this.permissionSelectTarget.value == "99-00") {
      this.permissionOthersTarget.style.display ="block";
    }
  }

  /**
   * 画像をタップして拡大
   */
  focusImage(event){
    const selectedImage = event.target;
    const popupContainer = this.popupContainerTarget;
    const selectedScaleImage = `<div class="popup_area">${selectedImage.outerHTML}<span class="close_btn"><i class="fa-solid fa-xmark"></i>閉じる</span></div>`;
    popupContainer.insertAdjacentHTML("afterbegin", selectedScaleImage);
    document.body.style.overflowY = "hidden";
    // 閉じるボタンが押されたら非表示(空要素に)
    $(".close_btn").on("click", function () {
      popupContainer.innerHTML = "";
      document.body.style.overflowY = "auto";
    });
  }


  // モーダル起動時、資格・許可証登録フォームに選択済みの license_id を保持する
  // Railsへ送信し、資格・許可証リストを再描画するときにチェック状態を復元する
  setLicenseIds() {
    if (!this.hasLicenseIdsTarget) return;

    const checkedLicense = document.querySelectorAll('.license_id:checked');
    let license_ids = [];
    for (const license of checkedLicense) {
      license_ids.push(license.value);
    }
    this.licenseIdsTarget.value = license_ids;
  }
}
