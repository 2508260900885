import { Controller } from "@hotwired/stimulus";

// 価格帯検索選択肢
const PRICE_LIST = [
  ["選択してください", ""],
  ["100円", 100],
  ["1,000円", 1000],
  ["2,000円", 2000],
  ["3,000円", 3000],
  ["4,000円", 4000],
  ["5,000円", 5000],
  ["6,000円", 6000],
  ["7,000円", 7000],
  ["8,000円", 8000],
  ["9,000円", 9000],
  ["10,000円", 10000],
  ["15,000円", 15000],
  ["20,000円", 20000],
  ["25,000円", 25000],
  ["30,000円", 30000],
];

const NO_UPPER_OPTION = ["30,001円以上", " "];

// Connects to data-controller="product-list-filter"
export default class extends Controller {
  connect() {}

  /**
   * 価格（上限）の設定
   */
  setUpperPriceSelectOptions() {
    const minPrice = document.getElementById("minPrice");
    const maxPrice = document.getElementById("maxPrice");

    // 下限金額に応じて選択肢を調整
    let priceList = PRICE_LIST.filter((option) => option[1] > minPrice.value);

    // 先頭に挿入
    priceList.unshift(["選択してください", ""]);

    // 末尾に挿入
    priceList.push(NO_UPPER_OPTION);

    maxPrice.innerHTML = priceList
      .map(
        (option) =>
          `<option value="${option[1]}" ${
            option[1] == maxPrice.value ? "selected" : ""
          }>${option[0]}</option>`,
      )
      .join("");
    maxPrice.disabled = false;
  }

  /**
   * サブカテゴリの設定
   */
  setSubCategorySelectOptions() {
    const categorySelect = document.querySelector("#categorySelect");
    const subCategorySelect = document.querySelector("#subCategorySelect");

    fetch(`/set_sub_categories/${categorySelect.value}`)
      .then((response) => response.json())
      .then((data) => {
        subCategorySelect.innerHTML = ""; // サブカテゴリの選択肢をクリア

        // "すべて"の選択肢を追加
        const allOption = document.createElement("option");
        allOption.value = "";
        allOption.textContent = "すべて";
        subCategorySelect.appendChild(allOption);

        data.forEach((subCategory) => {
          const option = document.createElement("option");
          option.value = subCategory.id;
          option.textContent = subCategory.name;
          subCategorySelect.appendChild(option);
        });
      });
  }

  /**
   * 絞り込み条件リセット
   */
  reset() {
    // 価格(下限)
    const minPrice = document.getElementById("minPrice");
    minPrice.innerHTML = PRICE_LIST.map(
      (option) => `<option value="${option[1]}" >${option[0]}</option>`,
    );

    // 価格(上限)
    const maxPrice = document.getElementById("maxPrice");
    this.setUpperPriceSelectOptions();
    let maxPriceList = [["選択してください", ""]];
    maxPrice.innerHTML = maxPriceList.map(
      (option) => `<option value="${option[1]}" >${option[0]}</option>`,
    );
    maxPrice.disabled = true;

    // カテゴリ
    document.getElementById("categorySelect").selectedIndex = 0;

    // サブカテゴリ
    document.getElementById("subCategorySelect").selectedIndex = 0;

    // 生産者の会員区分（全てチェックONが初期値）
    let checkboxList = document.querySelectorAll(".memberStatus");
    for (let i = 0; i < checkboxList.length; i++) {
      if (checkboxList.hasOwnProperty(i)) {
        checkboxList[i].checked = true;
        checkboxList[i].parentNode.classList.add("is_current");
        checkboxList[i].parentNode.parentNode.classList.add("is_current");
      }
    }

    // 販売中の商品のみ表示
    const nowOnSale = document.getElementById("nowOnSaleInsideModal");
    nowOnSale.checked = false;
    nowOnSale.parentNode.classList.remove("is_current");
    nowOnSale.parentNode.parentNode.classList.remove("is_current");

    // 送料無料の商品のみ表示
    const shippingIncluded = document.getElementById("shippingIncludedInsideModal");
    shippingIncluded.checked = false;
    shippingIncluded.parentNode.classList.remove("is_current");
    shippingIncluded.parentNode.parentNode.classList.remove("is_current");
  }

  /**
   * 絞り込み条件での商品検索
   * SP用検索ボックスに検索条件を入力した場合は、event.params.spMode に true が入る
   * カテゴリのリンククリック時は event.params.categoryId に選択したカテゴリのIDが入る
   */
  searchProducts(event) {
    // IME確定時のEnterを無視
    if (event.keyCode == 229) return false;

    this.setSearchParams(event.params.spMode, event.params.categoryId);
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件をhidden項目に保持
   */
  setSearchParams(spMode, categoryId, modal = true) {
    // SP用キーワードの取得、検索履歴の登録
    if (spMode) {
      const keyword = document.getElementById("keyword");
      const spKeywordValue = document.getElementById(
        "serviceSearchInput_sp",
      ).value;
      keyword.value = spKeywordValue;
      this.saveKeyword(spKeywordValue);
    }

    // カテゴリ
    const hiddenCategoryId = document.getElementById("hiddenCategoryId");

    // カテゴリのリンクをクリック時は categoryId が渡ってくる
    if (categoryId) {
      hiddenCategoryId.value = categoryId;

      // 絞り込みモーダルのカテゴリセレクト
    } else {
      const categorySelect = document.getElementById("categorySelect");
      const subCategorySelect = document.getElementById("subCategorySelect");
      if (subCategorySelect.value != "") {
        hiddenCategoryId.value = subCategorySelect.value;
      } else {
        hiddenCategoryId.value = categorySelect.value;
      }
    }

    // 価格(下限)
    const hiddenMinPrice = document.getElementById("hiddenMinPrice");
    const minPrice = document.getElementById("minPrice");
    hiddenMinPrice.value = minPrice.value;

    // 価格(上限)
    const hiddenMaxPrice = document.getElementById("hiddenMaxPrice");
    const maxPrice = document.getElementById("maxPrice");
    hiddenMaxPrice.value = maxPrice.value;

    // 生産者の会員区分
    let memberStatusArray = [];
    const checkboxList = document.querySelectorAll(".memberStatus");
    for (let i = 0; i < checkboxList.length; i++) {
      // checkボックスのON判定を is_current クラスの有無で判定
      if (checkboxList[i].parentNode.classList.contains("is_current")) {
        memberStatusArray.push(checkboxList[i].value);
      }
    }
    const hiddenMemberStatus = document.getElementById("hiddenMemberStatus");
    hiddenMemberStatus.value = memberStatusArray;


    // 販売中
    const hiddenNowOnSale = document.getElementById("hiddenNowOnSale");
     // 送料無料
    const hiddenShippingIncluded = document.getElementById(
      "hiddenShippingIncluded",
    );

    // 絞り込みモーダル内のチェックボックス
    const nowOnSaleInsideModal = document.getElementById("nowOnSaleInsideModal");
    const shippingIncludedInsideModal = document.getElementById("shippingIncludedInsideModal");
    // 絞り込みモーダル外のチェックボックス
    const nowOnSaleOutsideModal = document.getElementById("nowOnSaleOutsideModal");
    const shippingIncludedOutsideModal = document.getElementById("shippingIncludedOutsideModal");

    if (nowOnSaleOutsideModal != null) {
      const sourceCheckbox = modal ? nowOnSaleInsideModal : nowOnSaleOutsideModal;
      const targetCheckbox = modal ? nowOnSaleOutsideModal : nowOnSaleInsideModal;
      hiddenNowOnSale.value = sourceCheckbox.checked ? "1" : "0";
      targetCheckbox.checked = sourceCheckbox.checked;
    }

    if (shippingIncludedOutsideModal != null) {
      const sourceCheckbox = modal ? shippingIncludedInsideModal : shippingIncludedOutsideModal;
      const targetCheckbox = modal ? shippingIncludedOutsideModal : shippingIncludedInsideModal;
      hiddenShippingIncluded.value = sourceCheckbox.checked ? "1" : "0";
      targetCheckbox.checked = sourceCheckbox.checked;
    }
  }

  // 会員区分のチェックボックスON / OFF切り替え
  toggleCheckboxStyle(event) {
    event.target.parentNode.classList.toggle("is_current");
    event.target.parentNode.parentNode.classList.toggle("is_current");
  }

  // チェックボックスON / OFF切り替えを行い、検索を実行
  toggleOutsideModalCheckboxAndSearch(event) {
    // チェックボックスのON / OFF切り替え
    this.toggleCheckboxStyle(event);

    // SPの検索キーワード保存はなし、カテゴリクリックでもないのでcategoryIdはnull、モーダル外のチェックボックス切り替え時はmodalはfalse
    this.setSearchParams(false, null, false);

    // 検索実行
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 検索キーワードをローカルストレージに保存
   */
  saveKeyword(keyword) {
    const maxLength = 5; // 最大保存件数
    const serializedArray = localStorage.getItem("keywordArray");
    let keywordArray = JSON.parse(serializedArray);

    if (!keywordArray) keywordArray = [];

    // すでに保存済のワードであれば先頭に移動させるために一旦削除
    const keyIndex = keywordArray.indexOf(keyword);
    if (keyIndex > -1) keywordArray.splice(keyIndex, 1);

    // 先頭に追加
    keywordArray.unshift(keyword);

    // Max５件超過分は削除
    if (keywordArray.length > maxLength) keywordArray.splice(maxLength);

    // 保存
    const newSerializedArray = JSON.stringify(keywordArray);
    localStorage.setItem("keywordArray", newSerializedArray);
  }

  /**
   * 絞り込み条件リセット（全て）
   */
  resetSearchFilters(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenMinPrice.value = "";
    hiddenMaxPrice.value = "";
    hiddenCategoryId.value = "";
    hiddenNowOnSale.value = "0";
    hiddenShippingIncluded.value = "0";
    keyword.value = "";
    hiddenFarmerUid.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（価格）
   */
  resetPriceFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenMinPrice.value = "";
    hiddenMaxPrice.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（カテゴリ）
   */
  resetCategoryFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenCategoryId.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（サブカテゴリ）
   */
  resetSubCategoryFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.subCategoryId);
    // 親カテゴリIDを設定
    hiddenCategoryId.value = categorySelect.value;
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（販売中）
   */
  resetNowOnSaleFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenNowOnSale.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }
  /**
   * 絞り込み条件リセット（送料無料）
   */
  resetShippingIncludedFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenShippingIncluded.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（キーワード）
   */
  resetKeywordFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    keyword.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }

  /**
   * 絞り込み条件リセット（生産者）
   */
  resetFarmerFilter(event) {
    this.setSearchParams(event.params.spMode, event.params.categoryId);
    hiddenFarmerUid.value = "";
    document.getElementById("serviceSearchForm").requestSubmit();
  }
}
