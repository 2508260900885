import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    // モーダル生成
    this.productModal = Modal.getOrCreateInstance(document.getElementById('product_modal'))
    this.productModal.show()
  }
}
