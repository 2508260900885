import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="element-removal"
export default class extends Controller {
  connect() {
  }

  removeUrl() {
    this.element.remove();
  }
}
