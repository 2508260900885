import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="apply-basic-setting"
export default class extends Controller {
  connect() {
  }

  applyBasicSetting(event) {
    event.preventDefault();

    // ProductFromController#applyBasicSettingをコール
    const Event = new CustomEvent('apply_basic_setting');
    window.dispatchEvent(Event);
  }
}