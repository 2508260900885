import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-form"
export default class extends Controller {
  static targets = [
    'price',               // 商品価格
    'deliveryCharge',      // 送料
    'qty',                 // 数量
    'subTotal',            // 商品合計
    'deliveryPriceTotal',  // 送料合計
    'totalPrice'           // 総合計
  ]

  // 金額計算
  calcPrice() {
    // 商品合計
    const subTotal = this.priceTarget.value * this.qtyTarget.value;
    this.subTotalTarget.textContent = subTotal.toLocaleString();
    // 送料合計
    const deliveryPriceTotal = this.deliveryChargeTarget.value * this.qtyTarget.value;
    this.deliveryPriceTotalTarget.textContent = '¥' + deliveryPriceTotal.toLocaleString();
    // 総合計
    this.totalPriceTarget.textContent = (subTotal + deliveryPriceTotal).toLocaleString();
  }

  // お届け先変更時の金額再計算
  changeAddressId({ params: { deliveryCharge } }) {
    this.deliveryChargeTarget.value = Number(deliveryCharge);
    this.calcPrice();
  }

  // 届け先登録編集完了時にコールする用
  setDeliveryPrice() {
    const addressIds = document.querySelectorAll(".address_id");
    if (addressIds.length === 0) return;

    for (let i = 0; i < addressIds.length; i += 1) {
      // 選択状態のお届け先の送料で合計金額の計算処理を呼ぶ
      const element = addressIds[i];
      if (element.checked) {
        const selectedDeliverCharge = element.dataset.orderFormDeliveryChargeParam
        this.deliveryChargeTarget.value = selectedDeliverCharge;
        break;
      }
    }
    this.calcPrice();
  }
}
