import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bookmark-notification"
export default class extends Controller {
  static targets = ["buttonText", "buttonIcon"];

  connect() {
  }

  updateButtonText(event) {
    let radioState = event.detail.radioState;

    if (radioState === "on") {
      this.buttonTextTarget.textContent = "（現在の状態：ON）";
      this.buttonIconTarget.classList.remove("fa-regular", "fa-bell-slash");
      this.buttonIconTarget.classList.add("fa-regular", "fa-bell");
    } else {
      this.buttonTextTarget.textContent = "（現在の状態：OFF）";
      this.buttonIconTarget.classList.remove("fa-regular", "fa-bell");
      this.buttonIconTarget.classList.add("fa-regular", "fa-bell-slash");
    }
  }
}
