import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle";

// Connects to data-controller="review"
export default class extends Controller {
  static targets = [ "modal","comment","comment_confirm","images","images_confirm","no_comment","no_image"];
  connect() {
    this.reviewImageModal()
  }

  /**
   * 確認フォームへの切り替え
   */
  chgConfirm(){
    this.modalTarget.classList.toggle("is_confirm");
    this.comment_confirmTarget.innerText = this.commentTarget.value;
    if(this.commentTarget.value == ""){
      this.no_commentTarget.style.display  = "block";
    }else{
      this.no_commentTarget.style.display  = "none";
    }
    const ulElement = document.querySelector('.selected');
    const imgElements = ulElement.querySelectorAll('img');
    const ulElementTarget = document.querySelector('[data-review-target="images_confirm"]'); // 新しい<ul>要素

    if(imgElements.length == 0){
      this.no_imageTarget.style.display  = "block";

    }else{
      ulElementTarget.innerHTML = '';
      imgElements.forEach((imgElement) => {
        // 新しい<li>要素を作成し、クローンした<img>タグを追加する
        const liElement = document.createElement('li');
        const clonedImgElement = imgElement.cloneNode(true);
        liElement.appendChild(clonedImgElement);

        // 新しい<ul>要素に<li>要素を追加する
        ulElementTarget.appendChild(liElement);
      });

      this.no_imageTarget.style.display  = "none";
    }
  }


  /**
 * レビュー写真モーダル
 */
  reviewImageModal() {
    let reviewPicSwiper;
    let reviewPicSwiperThumb;

    $(function () {
      // モーダルを開く動作
      $('.js_review_img').click(function(){
        // レビューコメント返信モーダル内では動作させない（クリックされた要素から最も近い#modal_commentReplyが0以上存在する場合）
        if ($(this).closest('#modal_commentReply').length > 0) {
          return false;
        }
        const modal_id = $(this).closest('.review_box').data('modal-id');
        const modal = $("." + modal_id);
        reviewPicSwiperThumb = new Swiper("." + modal_id + " .swiperThumbnail", {
          // サムネイルの枚数
          slidesPerView: 4,
        });
        reviewPicSwiper = new Swiper("." + modal_id + " .swiperMain", {
          loop: true,
          // 前後の矢印
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          thumbs: {
            swiper: reviewPicSwiperThumb,
          },
        });

        let index = $(this).data('index');
        reviewPicSwiper.slideTo(index, 0, false);

        modal.fadeIn();
        $('body').css('overflow-y', 'hidden');
      });

      $(".js_reviewPicModalClose, .js_reviewPicModal").click(function () {
        const modal_id = $(this).closest('.review_box').data('modal-id');
        if (modal_id) {
          const modal = $("." + modal_id);
          modal.css('opacity', 0);

          // Swiperのインスタンスを破棄
          if (reviewPicSwiper) {
            reviewPicSwiper.destroy(true, true);
            reviewPicSwiper = null;
          }
          if (reviewPicSwiperThumb) {
            reviewPicSwiperThumb.destroy(true, true);
            reviewPicSwiperThumb = null;
          }
          modal.fadeOut(function() {
            modal.css('opacity', 1);
          });
          $('body').css('overflow-y','auto');
        }
      });

      $(".js_reviewPicModalClickArea").on("click", function (e) {
        e.stopPropagation();
      });
    });
  }
}
