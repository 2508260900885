import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle";

// Connects to data-controller="farmer-profiles"
export default class extends Controller {
  connect() {
    this.initSwiper();
    this.accordion();
  }

  initSwiper() {
    $(function () {
      // モーダル設定（PC・スマホ共通）
      const modalSwiper = new Swiper(".js_featureImgModal .swiperMain", {
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        thumbs: {
          swiper: new Swiper(".js_featureImgModal .swiperThumbnail", {
            slidesPerView: 10
          })
        }
      });

      // PCのSwiper設定(769px以上で発動)
      if (window.matchMedia("(min-width: 769px)").matches) {
        // モーダルを開く処理
        $(".js_featureImgModalOpen").click(function () {
          $(".js_featureImgModal").fadeIn();
          $("body").css("overflow-y", "hidden");
          // モーダルウィンドウの中のSwiperの初期位置を設定
          // クリックされたli要素のインデックスを取得
          const index = $(this).index();
          // モーダルウィンドウのSwiperの初期位置を設定
          modalSwiper.slideToLoop(index);
        });

        // モーダルを閉じる処理
        $(".js_featureImgModalClose, .js_featureImgModal").click(function () {
          $(".js_featureImgModal").fadeOut();
          $("body").css("overflow-y", "auto");
        });

        // イベントの伝搬を止める
        $(".js_featureImgModalClickArea").on("click", function (e) {
          e.stopPropagation();
        });
      }

      // スマホ用Swiper設定(768px以下で発動)
      if (window.matchMedia("(max-width: 768px)").matches) {
        const featureImgListSp_thumbnail = new Swiper(".list_img_sp_thumbnail", {
          // サムネイルの枚数
          slidesPerView: 10
        });

        const swiper = new Swiper(".list_img_sp", {
          loop: true,
          // Navigation arrows
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          thumbs: {
            swiper: featureImgListSp_thumbnail
          }
        });

        // モーダルを開く処理
        $(".js_featureImgModalOpen").click(function () {
          $(".js_featureImgModal").fadeIn();
          $("body").css("overflow-y", "hidden");
          // モーダルウィンドウの中のSwiperの初期位置を設定
          // loopがtrueの場合、実際のインデックスを取得
          const activeIndex = swiper.realIndex;
          // モーダルウィンドウのSwiperの初期位置を設定
          modalSwiper.slideToLoop(activeIndex);
        });

        // モーダルを閉じる処理
        $(".js_featureImgModalClose, .js_featureImgModal").click(function () {
          $(".js_featureImgModal").fadeOut();
          $("body").css("overflow-y", "auto");
        });

        // イベントの伝搬を止める
        $(".js_featureImgModalClickArea").on("click", function (e) {
          e.stopPropagation();
        });
      }
    });
  }

  accordion() {
    const setAccordion = (selector, heightLimit) => {
      // 親要素ボックス
      const readMoreArea = $(selector);
      // 詳細説明ボックス
      const readMoreInner = $(`${selector} .productDescription_inner`);
      // 「続きを見る」ボタン
      const readMoreBtn = $(`${selector} .btn_more`);
      // 詳細説明ボックス高さ
      const readMoreHeight = readMoreInner.height();
      const isSP = window.innerWidth <= 768;

      // heightLimit以上の高さだった場合、親要素ボックスに.is_accordionを付ける。
      if (readMoreHeight >= heightLimit) {
        readMoreArea.addClass("is_accordion");
        readMoreInner.css("max-height", heightLimit + "px");
      }

      // 詳細説明「続きを読む」「閉じる」の対応
      readMoreBtn.click(function () {
        if (readMoreArea.hasClass("is_active")) {
          readMoreArea.removeClass("is_active");
          readMoreBtn.removeClass("is_open")
          $(this).text("続きを読む");
          readMoreInner.css("max-height", heightLimit + "px");
        } else {
          readMoreArea.addClass("is_active");
          readMoreBtn.addClass("is_open")
          $(this).text("閉じる");
          readMoreInner.css("max-height", readMoreHeight + "px");
        }
      });
    }

    // 生産者のこだわり（高さ250pxを基準に続きを見るボタン表示)
    setAccordion(".feature", 250);
  }
}
