import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="category-select"
export default class extends Controller {
  static targets = [ "categoryId" ];

  // 大カテゴリのセレクトボックスを切替時にRails側へIDを送信する
  // Rails側はturboで中カテゴリのセレクトボックスを生成する
  async requestCategory() {
    const url = `/farmer/products/category_select?parent_category_id=${this.categoryIdTarget.value}`
    const response = get(url, {
      responseKind: "turbo-stream",
    })
  }
}
