import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-form-address-list"
export default class extends Controller {
  connect() {
    this.initAddressId();

    // ProductListFilterController#searchProducts をコール
    const event = new CustomEvent('set_delivery_price')
    window.dispatchEvent(event);
  }

  initAddressId() {
    // 選択されたお届け先の外枠のクラスを追加
    $('input[name="farmers_order[address_id]"]').on('change', function () {
      // 全てのクラスを外す
      $('.order_addressList li').removeClass('is_current');
      // checked状態かどうか判断
      if($(this).is(':checked')){
        // TRUE:枠線用クラス追加
        $(this).parents('li').addClass('is_current');
      }
    });
  }
}
