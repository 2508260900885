import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shop"
export default class extends Controller {
  static targets = [
                    'soleProprietorRadio',
                    'corporationsRadio',
                    'soleProprietorInput',
                    'corporationsInput',
                    'productDeliveryTime',
                    'cancelPolicy',
                    'agreeDisclose',
                    'submitBtn',
                  ]

  connect() {
    this.changeBusinessType();
  }

  // ビジネスタイプの変更
  changeBusinessType() {
    if (this.soleProprietorRadioTarget.checked) {
      // 個人事業主を選択した場合
      this.soleProprietorInputTarget.style.display ='block';
      this.soleProprietorRadioTarget.parentElement.parentElement.classList.add('is_current');
      this.corporationsInputTarget.style.display ='none';
      this.corporationsRadioTarget.parentElement.parentElement.classList.remove('is_current');

    }else{
      // 法人を選択した場合
      this.soleProprietorInputTarget.style.display ='none';
      this.soleProprietorRadioTarget.parentElement.parentElement.classList.remove('is_current');
      this.corporationsInputTarget.style.display ='block';
      this.corporationsRadioTarget.parentElement.parentElement.classList.add('is_current');
    }
  }

  // 基本テンプレ挿入（商品の引き渡し時期）
  setProductDeliveryTime() {
    this.productDeliveryTimeTarget.value = '商品代金の着金の確認ができた時点から、各商品ページに記載の「発送までの目安」の日までに、生産者より発送いたします。';
  }

  // 基本テンプレ挿入（返品、交換、キャンセルに関する事項）
  setCancelPolicy() {
    this.cancelPolicyTarget.value = '食品に関しては、購入者のご都合によるキャンセル・返品・交換は一切受け付けません。\n商品の欠陥、数量不足、滅失その他商品に瑕疵がある場合には、生産者の責任にて商品の返品又は交換を行います。';
  }

  // 同意するにチェックが入っているかどうか
  toggleAgreeDisclose() {
    this.submitBtnTarget.disabled = !this.agreeDiscloseTarget.checked;
  }

}