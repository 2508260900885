import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transaction-histories"
export default class extends Controller {
  static targets = ['order', 'requireReview']

  connect() {
    if (this.requireReviewTarget.value === 'true') {
      const goodButton = document.getElementById('good-button');

      if (goodButton) {
        // 2秒後にボタンを活性にする（モーダル自動表示よりも1秒後にする）
        setTimeout(function() {
          goodButton.classList.toggle("not_events");
        }, 2000);
      }

      setTimeout(() => {
        // いいねモーダルが存在してない場合に表示させる
        const modal_good = document.getElementById("modal_good");
        if (modal_good == null) {
          this.showModal();
        }
      }, 1000);
    }
  }

  /**
   * モーダルの表示
   */
  async showModal(){
    const response = await fetch("/reviews/new/" + this.orderTarget.value, {
      method: "GET",
      headers: {
        "Content-Type": "text/vnd.turbo-stream.html",
        "X-Requested-With": "XMLHttpRequest",
        "Turbo-Frame": "good_modal_frame",
      },
    });

    if (response.ok) {
      const responseData = await response.text();
      const parser = new DOMParser();
      const turboStream = parser.parseFromString(responseData, "text/html");
      Turbo.renderStreamMessage(turboStream.documentElement.innerHTML);
    }
  }
}
