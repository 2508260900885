
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="underage"
export default class extends Controller {
  static targets = ['underageAgree','agreecheckArea', 'jsBtnSubmit', 'targetPath'];

  connect() {
    this.controllSubmitButton();
    this.setTargetPath();
  }

  /**
   * ボタン制御
   */
  controllSubmitButton() {
    // 画面読み込み時
    if(this.underageAgreeTarget.checked){
      this.agreecheckAreaTarget.classList.add('is_checked');
      this.jsBtnSubmitTarget.disabled = false;

    } else {
      this.agreecheckAreaTarget.classList.remove('is_checked');
      this.jsBtnSubmitTarget.disabled = true;
    }
 }
  /**
   * 遷移先のパスをセット
   */
  setTargetPath() {
    const modalTrigger = document.querySelector('[data-bs-toggle="modal"][data-bs-target="#underageModal"]');
    if (modalTrigger) {
      const targetPath = modalTrigger.getAttribute('data-target-path');
      this.targetPathTarget.value = targetPath;
    }
  }
}