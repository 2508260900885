import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="header-search-sp"
export default class extends Controller {
  static targets = ["searchWindow", "cancelledBtn", "menuBox", "historyWindow", "historyTitle", "historyList"];

  // モック common.js の「【スマホ】検索窓」の処理
  connect() {
    this.startPos = 0;
    window.addEventListener("scroll", this.onScroll.bind(this));

    // 検索履歴の表示
    this.buildHistoryListHtml();
  }

  /**
   * スクロール（上スクロール時検索窓を表示・下スクロール非表示）
   */
  onScroll() {
    // 検索窓選択時はスクロールしない
    if (!this.searchWindowTarget.classList.contains("is_fixed")) {
      // スクロール量の取得 iPhoneスクロールバインド対策
      const winScrollTop = window.pageYOffset;
      if (winScrollTop >= this.startPos && winScrollTop > 100) {
        // 上にスクロールしたとき
        this.searchWindowTarget.classList.add("is_hide");
      } else {
        // 下にスクロールしたとき
        this.searchWindowTarget.classList.remove("is_hide");
      }
      // 現在位置を更新
      this.startPos = winScrollTop;
    }
  }

  /**
   * 【SP】検索窓タップで検索履歴エリアを開く
   */
  toggleSearchArea(event) {
    event.preventDefault();
    this.cancelledBtnTarget.classList.remove("is_hide");
    // 検索履歴表示時は検索窓はスクロールさせない
    this.searchWindowTarget.classList.add("is_fixed");
    // お気に入り・注文履歴ボタン非表示
    this.menuBoxTarget.classList.add("is_hide");
    // 検索履歴・カテゴリを表示
    this.historyWindowTarget.classList.remove("is_hide");
  }

  /**
   * 【SP】キャンセルボタンクリックで検索履歴エリアを閉じる
   */
  resetSearch(event) {
    event.preventDefault();
    this.cancelledBtnTarget.classList.add("is_hide");
    this.searchWindowTarget.classList.remove("is_fixed");
    this.menuBoxTarget.classList.remove("is_hide");
    this.historyWindowTarget.classList.add("is_hide");
  }

  /**
   * ローカルストレージの検索ワード一覧を取得し、検索履歴のHTMLを生成する
   * app/views/shared/_header.html.erb 内の ul.main_cat 配下にリストを追加
   */
  buildHistoryListHtml() {
    const serializedArray = localStorage.getItem('keywordArray');
    const keywordArray = JSON.parse(serializedArray);

    if (!keywordArray) {
      // 検索履歴エリアを非表示
      this.historyTitleTarget.classList.add("d-none");
      this.historyListTarget.classList.add("d-none");
      return;
    }
    // 検索ワードのリストから li タグを生成
    keywordArray.forEach((keyword) => {
      const li = document.createElement("li");
      li.classList.add("main_cat_item");

      const a = document.createElement("a");
      a.setAttribute("href", `/search?keyword=${keyword}`);
      a.classList.add("main_cat_inner");

      const span = document.createElement("span");
      span.classList.add("cat_name");
      span.textContent = keyword;

      const div = document.createElement("div");
      div.classList.add("wrap");

      const i = document.createElement("i");
      i.classList.add("fal", "fa-chevron-right");

      div.appendChild(i);
      a.appendChild(span);
      a.appendChild(div);
      li.appendChild(a);

      // 出力先の要素（ul）に追加
      this.historyListTarget.appendChild(li);
    });
  }
}
