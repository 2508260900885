import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template-addition"
export default class extends Controller {
  static targets = ['container', 'template'];

  connect() {
    this.controllFooterButton();
  }

  /**
   * 最下部固定のボタン制御
   */
  controllFooterButton() {
    $(window).on("scroll", function () {
      let pageHeight = $(document).innerHeight();
      let sclTop = $(window).scrollTop();
      let winHight = window.innerHeight;
      let relPos = pageHeight - winHight - sclTop;

      const BASE_VALUE = 100;

      // 下までスクロールすると表示切替え
      if (relPos < BASE_VALUE) {
        $(".sell_footBtn").fadeOut();
      } else {
        $(".sell_footBtn").fadeIn();
      }

      // 上&下から基準値までスクロールすると表示切替え
      if (window.matchMedia("(min-width: 769px)").matches) return;
      if (relPos < BASE_VALUE) {
        $(".footer_farmerBtn").fadeOut();
      } else {
        $(".footer_farmerBtn").fadeIn();
      }
    });
  }
}
