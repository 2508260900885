import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template-addition"
export default class extends Controller {
  static targets = ['container', 'template'];

  connect() {
  }

  addUrl() {
    console.log('addUrl');
    this.containerTarget.append(this.templateTarget.content.cloneNode(true));
  }
}
