import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea-validation"
export default class extends Controller {

  connect() {
    this.textareaValidation('.limit_text_1000', 1000)
    // this.textareaValidation('.limit_text_140', 140) // 共通化する場合は追加する
  }

  // バリデーションエラー後の再レンダリング時にエラーを表示
  textareaValidation(selector, limitNUm) {
    // テキストエリアの文字制限
    document.querySelectorAll(selector).forEach(element => this.limitCheck(element, limitNUm))
  }

  // onClickで文字数チェックdata属性からパラメータを受け取る
  checkTextarea({ params: { id, limit } }) {
    this.limitCheck(document.getElementById(id), limit)
  }

  // 文字数チェック
  limitCheck(element, limitNUm) {
    // 制限文字数に達したらエラーメッセージ
    if (element.value.length > limitNUm) {
      element.classList.add("is_error");
    } else {
      element.classList.remove("is_error");
    }
  }
}
