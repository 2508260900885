import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification-for-resale"
export default class extends Controller {
  static targets = ['submit'];

  submitForm(e) {
    // 活性化の装飾を切り替え
    e.currentTarget.closest('li').classList.add('is_current');
    if (e.currentTarget.id === 'on') {
      document.getElementById('off').closest('li').classList.remove('is_current');
    } else {
      document.getElementById('on').closest('li').classList.remove('is_current');
    }
    // 隠しているsubmitボタンをクリックするだけ
    this.submitTarget.click();
  }
}
