import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="orders"
export default class extends Controller {
  connect() {
    this.selectProducts()
    this.sortOrders()
    this.chkFarmerPhase()
  }

  /**
   * 商品を選択するとフォームを送信する
   *
   */
  selectProducts(){
    const trigger = document.getElementById("productSelect");
    if (trigger === null) return;
    trigger.addEventListener("change", () => {
      const hiddenProduct = document.getElementById("hiddenProduct");
      const productSelect = document.getElementById("productSelect");
      hiddenProduct.value = productSelect.value;

      const form = document.getElementById("ordersForm");
      form.requestSubmit();
    });
  }


  /**
   * 並び替えを選択するとフォームを送信する
   *
   */
  sortOrders(){
    const trigger = document.getElementById("sortSelect");
    if (trigger === null) return;
    trigger.addEventListener("change", () => {
      const hiddenSort = document.getElementById("hiddenSort");
      const sortSelect = document.getElementById("sortSelect");
      hiddenSort.value = sortSelect.value;

      const form = document.getElementById("ordersForm");
      form.requestSubmit();
    });
  }

  /**
   * チェックボックスの制御
   */
  chkFarmerPhase(){
    const checkbox = $('input[type="checkbox"] ');
    checkbox.on("change", function (e) {
      e.target.parentNode.classList.toggle("is_current");
      e.target.parentNode.parentNode.classList.toggle("is_current");

      const hiddenCheck = document.getElementById("hiddenCheck");
      if(e.target.parentNode.classList.contains("is_current")){
        hiddenCheck.value = "on";
      } else {
        hiddenCheck.value = "";
      }

      const form = document.getElementById("ordersForm");
      form.requestSubmit();
    });
  }
}
